#calendar tbody tr td {
    height: 35px;
}

#calendar * {
    box-sizing: border-box !important;
}

#calendar th {
    background-color: rgba(0,0,0,.075);
}

#calendar th * {
    padding: 5px 10px;
    display: block;
    font-weight: normal;
}

#calendar thead.fc-head {
    background: $white;
}

.fc-title, .fc-time {
    font-size: 10px !important;
}

.fc-disabled-day {
    padding: 5px 10px !important;
    font-weight: normal;
    background: #d7d7d7;
    opacity: .3;
}

.fc-day.fc-today {
    background-color: #fff3cd;
}

.fc-list-heading .table-active {
    font-weight: normal
}

.fc-header-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    background: linear-gradient(180deg,#2ea1f8,#1990ea);
    // background: linear-gradient(90deg, #3F2B96 0%, #A8C0FF 100%);
    // background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
    // background: #212529;;
}

.fc-clear {
    display: none;
}

.fc-center {
    order: 1;
    color: white;
    padding: 10px 20px;
}

.fc-center h2 {
    color: white;
    margin: auto;
    font-size: 18px;
    float: none !important;
    font-weight: normal !important;
}

.fc-left {
    order: 2;
    text-align: right;
    padding: 10px 20px;
    float: none !important;
}

.fc-left * {
    float: none !important;
}

.fc-left button {
    float: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.fc-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    order: 3;
    text-align: left;
    padding: 10px 20px;
    background: #343a40;
}

.fc-right button {
    float: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.fc-event-container {
    margin: 0 !important;
    width: 100%;
}

.fc-event * {
    box-sizing: content-box
}

.fc-event {
    box-sizing: content-box;
    margin: 3px 3px 3px 3px;
    padding: 0 !important;
}

.fc-past {
    background: rgba(238, 238, 238, 0.8)
}

.fc-time span {
    white-space: normal;
}

.fc-event, .fc-event-dot {
    text-align: center;
    padding: 2px;
}

.iziToast-buttons-child {
    outline: none !important;
    box-shadow: none !important;
}


.fc-guidelinesButton-button {
    padding-right: 20px !important;
    font-weight: 400;
    color: $white;
    background-color: transparent;   
    border-color: transparent;
    position: relative;
    
    &::after {
        content: '?';
        height: 15px;
        width: 15px;
        font-size: $small-font-size;
        transform: translate(0,-50%);
        line-height: 14px;
        border: 1px solid $white;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 50%;
    }

    &:hover, &:focus, &:active {
        color: darken($white, 7.5%) !important;
        text-decoration: none !important;
        background-color: transparent !important;
        border-color: transparent !important;

        &::after{
            border-color: darken($white, 7.5%) !important;
        }
    }
}

@media only screen and (max-width: 350px) {
    .fc-left {
        padding-top: 0;
    }
    .fc-left, .fc-right, .fc-center {
        width: 100%;
        text-align: center;
    }
}