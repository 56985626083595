// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.custom-control-label {
    line-height: 2;
}

body * {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

.dropdown-toggle:focus, .dropdown-toggle:active {
    outline: none !important;
    box-shadow: none !important;
}